import CheckoutLoginDropdown from '@theme/components/cart/CheckoutLoginDropdown'

export default {
  props: {
    redirect: String,
  },
  components: {
    CheckoutLoginDropdown,
  },
  methods: {
    handleLoginLinkClick(e) {
      if (this.$themeSettings.components.CheckoutLoginLineHook.loginLink.type !== 'link') {
        e.preventDefault()
        if (this.$themeSettings.components.CheckoutLoginLineHook.loginLink.type === 'modal') {
          this.openLoginModal(e)
        }
      }
    },
  },
}
